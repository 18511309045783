import React from 'react';
import { Link } from 'gatsby';

import BackButton from '../components/common/back-button';
import Row from '../components/common/row';
import Column from '../components/common/column';
import AppStore from '../components/common/app-store';
import GooglePlay from '../components/common/google-play';

import "../components/projects/projects.css";

import headerImg from '../images/projects/screen2.png';
import screen3 from '../images/projects/screen3.png';
import screen4 from '../images/projects/screen4.png';
import screen5 from '../images/projects/screen5.png';
import screen6 from '../images/projects/screen6.png';
import screen7 from '../images/projects/screen7.png';

const SuperlistaPage = () => {
  return (
    <div className="project-container superlista">
      <div className="content-fluid">
        <Link to="/">
          <BackButton color="#FF6A8F" />
        </Link>
        <div className="header">
          <div className="title">
            Superlista
          </div>
          <Row>
            <Column className="column-padded" justify="center">
              <p className="description">
                Honestly, I got tired of making my shopping list and always forgetting it at home, 
                so I used my skills to develop an application to always have it at hand!
              </p>
              <p className="description">
                And here it is...
              </p>
            </Column>
            <Column className="image-container superlista">
              <img src={headerImg} alt="superlista" />
            </Column>
          </Row>
        </div>
        <div className="content">
          <p className="description">
            It is very easy to use, you only have to go through the different categories choosing the products 
            you need to buy and then, when you have bought them, you remove them from your list and go!
          </p>
          <p className="description">
            This experience was very fun for me, so I'm going to add more features soon!
          </p>
        </div>
      </div>
      <div className="gallery">
        <img src={screen3} alt="superlista" />
        <img src={headerImg} alt="superlista" />
        <img src={screen4} alt="superlista" />
        <img src={screen5} alt="superlista" />
        <img src={screen6} alt="superlista" />
        <img src={screen7} alt="superlista" />
      </div>
      <div className="footer">
        <div className="content">
          <div className="note">
            This application is available for iOS and Android in Argentina and Uruguay for now.
          </div>
          <div className="download">
            DOWNLOAD
          </div>
          <div className="download-buttons">
            <AppStore link="https://itunes.apple.com/us/app/superlista-by-ns/id1462705039?l=es&ls=1&mt=8" />
            <GooglePlay link="https://play.google.com/store/apps/details?id=com.ns.superlista.app"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperlistaPage;